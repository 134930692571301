import React from 'react';
import { UsecasePageValues } from './usecasePageTypes';
import UsecasePage from './usecasePage';

import { getFluidImage, getFluidImages } from '../../appGatsbyImageUtils';
import { AppGatsbyImageFluid } from '../../primitives/appImage';

import { graphql } from 'gatsby';
import {
  getUsecaseMessage,
  getUsecaseTimelineCaption,
} from './usecaseMessages';
import { UsecaseBreathePageQuery } from '../../appGraphQLTypes';
import { UsecaseName } from '../../appConstantsUsecase';
import useVideos from '../../queries/useVideos';
import useShoootinProjectManagers from '../../queries/useShoootinProjectManager';
import { ShIntlMessageDefinition } from '@shoootin/translations';

const extractImages = (
  data: UsecaseBreathePageQuery,
): {
  heroImage: AppGatsbyImageFluid;
  introImg: AppGatsbyImageFluid;
  step1Image: AppGatsbyImageFluid;
  step1Media: AppGatsbyImageFluid[];
  step3Image: AppGatsbyImageFluid;
  outro: AppGatsbyImageFluid;
} => {
  return {
    heroImage: getFluidImage(data.heroImage!, 'heroImage'),
    introImg: getFluidImage(data.introImg!, 'introImg'),
    step1Image: getFluidImage(data.step1Image!, 'step1Image'),
    step1Media: getFluidImages(data, 'step1Media'),
    step3Image: getFluidImage(data.step3Image!, 'step3Image'),
    outro: getFluidImage(data.outro!, 'outro'),
  };
};

export const useUsecaseValues = (
  data: UsecaseBreathePageQuery,
): UsecasePageValues => {
  const usecase: UsecaseName = 'breathe';
  const video = useVideos()[usecase];
  const pm = useShoootinProjectManagers().Marie;

  const getMessage = (category: string, key: string) =>
    getUsecaseMessage(category, key, usecase);

  const getTimelineCaption = (
    category: string,
    key: string,
    i: number,
  ): ShIntlMessageDefinition =>
    getUsecaseTimelineCaption(category, key, usecase, i);

  const {
    heroImage,
    introImg,
    step1Image,
    step1Media,
    step3Image,
    outro,
  } = extractImages(data);

  return {
    name: 'breathe',
    heroImg: heroImage,
    teaserImg: step1Image,
    introImg,
    step1: {
      title: getMessage('UsecaseStep1', 'title'),
      subtitle: getMessage('UsecaseStep1', 'subtitle'),
      img: step1Image,
      media: {
        type: 'Gallery',
        gallery: step1Media,
      },
    },
    step2: {
      title: getMessage('UsecaseStep2', 'title'),
      subtitle: getMessage('UsecaseStep2', 'subtitle'),
      img: video.videoImg,
      quote: getMessage('UsecaseStep2', 'quote'),
      media: {
        type: 'Video',
        videoId: video.videoUrl,
      },
    },
    step3: {
      title: getMessage('UsecaseStep3', 'title'),
      subtitle: getMessage('UsecaseStep3', 'subtitle'),
      img: step3Image,
      quote: getMessage('UsecaseStep3', 'quote'),
      media: undefined,
    },
    // step4: {
    //   title: getMessage('UsecaseStep4', 'title'),
    //   subtitle: getMessage('UsecaseStep4', 'subtitle'),
    //   gallery: [],
    //   img: '',
    // },
    project: {
      name: pm.managerName,
      img: pm.managerImg,
      title: getMessage('UsecaseProject', 'title'),
      subtitle: getMessage('UsecaseProject', 'subtitle'),
      quote: getMessage('UsecaseProject', 'quote'),
      role: getMessage('UsecaseProject', 'role'),
    },
    outro: {
      img: outro,
      quote: getMessage('UsecaseOutro', 'quote'),
    },
    timeline: {
      title: getMessage('UseCaseTimeline', 'title'),
      steps: [
        {
          date: new Date(2019, 1, 9, 15),
          caption: getTimelineCaption('UseCaseTimeline', 'captions', 0),
        },
        {
          date: new Date(2019, 1, 10, 11),
          caption: getTimelineCaption('UseCaseTimeline', 'captions', 1),
        },
        {
          date: new Date(2019, 1, 11, 19),
          caption: getTimelineCaption('UseCaseTimeline', 'captions', 2),
        },
        {
          date: new Date(2019, 1, 12, 9),
          caption: getTimelineCaption('UseCaseTimeline', 'captions', 3),
        },
      ],
    },
  };
};

const UsecaseBreathe = ({ data }: { data: UsecaseBreathePageQuery }) => {
  const values = useUsecaseValues(data);
  return <UsecasePage values={values} />;
};

export default UsecaseBreathe;

export const query = graphql`
  query UsecaseBreathePage {
    heroImage: file(relativePath: { eq: "usecase_breathe-header.jpg" }) {
      ...AppFluidHeroImageFragment
    }
    introImg: file(relativePath: { eq: "usecase_breathe-intro.jpg" }) {
      ...UsecasePageIntroImage
    }
    step1Image: file(relativePath: { eq: "usecase_breathe-gallery-9.jpg" }) {
      ...UsecasePageStep2Image
    }
    step1Media: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativePath: { glob: "usecase_breathe-gallery-*.jpg" } }
    ) {
      ...UsecasePageStep1Media
    }
    step3Image: file(relativePath: { eq: "usecase_breathe-gallery-14.jpg" }) {
      ...UsecasePageStep3Image
    }
    outro: file(relativePath: { eq: "usecase_breathe-gallery-7.jpg" }) {
      ...UsecasePageOutroImage
    }
  }
`;
